.loginButton {
  box-sizing: border-box !important;
  border: 2px solid black !important;
  border-radius: 10px !important;
  display: flex;
  padding: 10px;
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  transition: background-color 0.5s linear;
  transition: border-radius 0.3s ease;

  &:focus-visible {
    border-radius: 0px !important;
  }
  &:disabled {
    // border-color: grey !important;
    color: lightgrey !important;
  }

  .buttonText {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    margin: 0 13px;
  }
}
